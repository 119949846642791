import React from "react";
import {
  getDayFromDate,
  getMonthNameFromDate,
} from "../../utilities/getDayFromDate";
import { NewsAndEventsProps } from "../../modals/Modals";

export default function NewsCard({
  title,
  description,
  eventdate,
}: NewsAndEventsProps) {
  return (
    <div className="w-full mb-4">
      <div className="relative border border-ternary-dark pl-8 py-4 pr-4 bg-ternary">
        <p className="text-primary font-bold pb-2">{title}</p>
        <p className="text-sm text-gray-700">{description}</p>
        <div className="w-14 h-14 bg-primary absolute -left-10 top-1/4 flex flex-col items-center justify-center text-white text-sm">
          <p className="border-b">{getDayFromDate(eventdate)}</p>
          <p className="pt-1">{getMonthNameFromDate(eventdate)}</p>
        </div>
      </div>
    </div>
  );
}
