import React from "react";

interface Props {
  holiday: {
    month: string;
    holidays: {
      dates: string;
      occasion: string;
    }[];
    bgcolor: string;
  };
}

export default function CalendarView({ holiday }: Props) {
  return (
    <div
      className={`${holiday.bgcolor} rounded shadow-lg p-4 hover:scale-95 transition-all duration-300`}
    >
      <p className="p-2 text-center bg-white text-gray-700 mb-4 rounded font-bold">
        {holiday.month}
      </p>
      <ul className="max-w-md space-y-1 list-disc list-inside px-2">
        {holiday?.holidays?.map((data) => (
          <li className="text-xs lg:text-sm">
            {data.dates}&nbsp;:&nbsp;
            {data.occasion}
          </li>
        ))}
      </ul>
    </div>
  );
}
