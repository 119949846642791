import React from "react";
import useAPIData from "../../hooks/useAPIData";
import { BASE_URL, KEY_INFO_SECTION } from "../../apiRoutes";

const API_URL = BASE_URL + KEY_INFO_SECTION;

const KeyInformation = () => {
  const { data } = useAPIData(API_URL);
  return (
    <div className="p-16 bg-gray-100 flex flex-col justify-center items-center">
      <div className="container mx-auto">
        <h1 className="text-center text-xl lg:text-2xl xl:text-3xl font-bold">
          Key Information
        </h1>
        <div className="grid grid-cols-4 gap-1 w-full mt-16">
          <div className="col-span-4 md:col-span-1 bg-gray-200 rounded-l-xl p-8 text-center">
            <h1 className="text-xl md:text-2xl xl:text-4xl font-semibold pb-2">
              {data?.attributes?.SchoolEstablishedYear}
            </h1>
            <p className="text-primary">Established</p>
          </div>
          <div className="col-span-4 md:col-span-1 bg-gray-200 p-8 text-center">
            <h1 className="text-xl md:text-2xl xl:text-4xl font-semibold pb-2">
              {data?.attributes?.TotalSchoolStrength}
            </h1>
            <p className="text-primary">School Strength</p>
          </div>
          <div className="col-span-4 md:col-span-1 bg-gray-200 p-8 text-center">
            <h1 className="text-xl md:text-2xl xl:text-4xl font-semibold pb-2">
              {data?.attributes?.AverageClassStrength}
            </h1>
            <p className="text-primary">Average Class Strength</p>
          </div>
          <div className="col-span-4 md:col-span-1 bg-gray-200 rounded-r-xl p-8 text-center">
            <h1 className="text-xl md:text-2xl xl:text-4xl font-semibold pb-2">
              {data?.attributes?.FacultiesStrength}
            </h1>
            <p className="text-primary">Faculties</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KeyInformation;
