import React, { useState } from "react";
import Layout from "../components/Layout";
import CalendarView from "../components/cards/CalendarView";

export default function AcademicCalendar() {
  const [holidayList, setHolidayList] = useState([
    {
      month: "March",
      holidays: [
        { dates: "28 March - 2 June", occasion: "Provision of text book" },
      ],
      bgcolor: "bg-amber-100",
    },
    {
      month: "April-May",
      holidays: [{ dates: "", occasion: "Summer Break" }],
      bgcolor: "bg-green-100",
    },
    {
      month: "June",
      holidays: [
        { dates: "5", occasion: "Class Begin" },
        { dates: "Lats Week", occasion: "PTM (Parents Teacher Meeting)" },
      ],
      bgcolor: "bg-lime-100",
    },
    {
      month: "July",
      holidays: [
        { dates: "", occasion: "Holiday as per Government Calendar" },
        { dates: "3 Week", occasion: "1 Periodic Test" },
      ],
      bgcolor: "bg-red-100",
    },
    {
      month: "August",
      holidays: [{ dates: "19", occasion: "School Foundation Day" }],
      bgcolor: "bg-lime-200",
    },
    {
      month: "September",
      holidays: [{ dates: "2 Week", occasion: "2 Peridoc test" }],
      bgcolor: "bg-indigo-100",
    },
    {
      month: "October",
      holidays: [
        { dates: "", occasion: "1 Week Half Yearly" },
        { dates: "18-28", occasion: "Dussera Holiday" },
        { dates: "PTM (Parents Teacher Meeting)", occasion: "On Result Day" },
      ],
      bgcolor: "bg-purple-100",
    },
    {
      month: "November",
      holidays: [{ dates: "6-13", occasion: "Sports Week" }],
      bgcolor: "bg-yellow-100",
    },
    {
      month: "December",
      holidays: [
        { dates: "2 Week", occasion: "3 Periodic Test" },
        { dates: "20", occasion: "School Picnic" },
        { dates: "23-8 Jan", occasion: "Winter Break" },
      ],
      bgcolor: "bg-cyan-100",
    },
    {
      month: "January",
      holidays: [{ dates: "", occasion: "Holiday as per Government Calendar" }],
      bgcolor: "bg-orange-100",
    },
    {
      month: "February",
      holidays: [
        { dates: "1 Week", occasion: "4 Periodic Test" },
        { dates: "8-14", occasion: "Lossar Holiday" },
        { dates: "PTM (Parents Teacher Meeting)", occasion: "On Result Day" },
      ],
      bgcolor: "bg-teal-100",
    },
    {
      month: "March",
      holidays: [
        {
          dates: "2 Week",
          occasion: "Final Examination as per Government Order",
        },
      ],
      bgcolor: "bg-pink-50",
    },
  ]);
  return (
    <Layout>
      <div className="container mx-auto py-10 lg:py-20 px-4">
        <p className="font-medium text-lg lg:text-2xl text-center border-b-2 pb-6 border-primary">
          Academic Calendar 2023-2024
        </p>
        <div>
          {holidayList?.length > 0 ? (
            <div className="grid md:grid-cols-3 lg:grid-cols-4 gap-6 my-8">
              {holidayList?.map((holiday) => (
                <CalendarView holiday={holiday} />
              ))}
            </div>
          ) : (
            <div>No Holidays</div>
          )}
        </div>
      </div>
    </Layout>
  );
}
