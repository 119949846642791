import React from "react";
import Navigation from "./Navigation";
import Footer from "./Footer";
interface Props {
  children: JSX.Element;
}
const Layout: React.FC<Props> = ({ children }) => {
  return (
    <>
      <Navigation />
      <main>{children}</main>
      <Footer />
    </>
  );
};

export default Layout;
