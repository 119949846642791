import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper";
import { BASE_URL, CORE_TEAMS } from "../../apiRoutes";
import useAPIDataList from "../../hooks/useAPIDataList";

const API_URL = BASE_URL + CORE_TEAMS + "?populate=*";

export default function CoreTeam() {
  const { data } = useAPIDataList(API_URL);
  return (
    <div className="py-14 p-4">
      <p className="text-secondary text-lg lg:text-2xl text-center">
        CORE <span className="text-white">TEAM</span>
      </p>
      <Swiper
        spaceBetween={50}
        slidesPerView={1}
        navigation
        pagination={{
          clickable: true,
        }}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        modules={[Pagination, Autoplay]}
      >
        {data?.map((data) => (
          <SwiperSlide key={data.id}>
            <div className="grid grid-cols-5 py-6 mt-8">
              <div className="col-span-5 lg:col-span-1 flex justify-center mb-6 lg:mb-0">
                <img
                  src={data?.attributes?.Photo?.data?.attributes?.url}
                  className="h-32 w-32 object-cover rounded-full border-4 -mt-6"
                />
              </div>
              <div className="col-span-5 lg:col-span-4 text-white">
                <p className="text-sm lg:text-base text-justify">
                  {data?.attributes?.Message}
                </p>
                <p className="text-secondary mt-3">
                  {data?.attributes?.PersonName}
                </p>
                <p className="text-sm pt-1">{data?.attributes?.Designation}</p>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
