import React from "react";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";

interface Props {
  link: string;
  label: string;
}

const BoxLink: React.FC<Props> = ({ link, label }) => {
  return (
    <div className="border rounded-xl p-6 max-w-max cursor-pointer hover:bg-primary hover:text-white text-gray-600 transition-all duration-100">
      <p className="text-sm lg:text-base">{label}</p>
      <HiOutlineArrowNarrowRight size={30} className="mt-2" />
    </div>
  );
};

export default BoxLink;
