const getDayFromDate = (date) => {
  let dt = new Date(date);
  let day = dt.getDay();
  if (parseInt(day) < 9) {
    day = "0" + day;
  }
  return day;
};

const getMonthNameFromDate = (date) => {
  const monthNames = [
    "JAN",
    "FRB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];
  let dt = new Date(date);
  let month = dt.getMonth();
  return monthNames[month];
};

function truncateString(inputString, maxLength) {
  if (inputString.length <= maxLength) {
    return inputString;
  } else {
    return inputString.substring(0, maxLength) + "...";
  }
}

module.exports = { getDayFromDate, getMonthNameFromDate, truncateString };
