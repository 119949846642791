import React, { useCallback, useEffect, useState } from "react";
import Layout from "../components/Layout";
import ImageViewer from "react-simple-image-viewer";
import { BASE_URL, GALLERY } from "../apiRoutes";
import useAPIDataList from "../hooks/useAPIDataList";

const API_URL = BASE_URL + GALLERY + "?populate=*";

const Gallery = () => {
  const { data } = useAPIDataList(API_URL);
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [galleryImages, setGalleryImages] = useState<any>([]);

  const openImageViewer = useCallback((id: number) => {
    setCurrentImage(id);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const onClickView = (id: number) => {
    openImageViewer(id);
  };

  useEffect(() => {
    if (data) {
      const images = [];
      for (let i = 0; i < data.length; i++) {
        images.push(data[i]?.attributes?.Photo?.data?.attributes?.url);
      }
      setGalleryImages(images);
    }
  }, [data]);

  return (
    <Layout>
      <div className="container mx-auto py-16 px-4">
        <h1 className="text-center text-xl lg:text-2xl xl:text-3xl font-bold mb-8">
          Gallery
        </h1>
        {isViewerOpen && (
          <ImageViewer
            src={galleryImages}
            currentIndex={currentImage}
            disableScroll={false}
            closeOnClickOutside={true}
            onClose={closeImageViewer}
          />
        )}
        <div className="grid grid-cols-3 gap-4">
          {data?.map((gallery, index) => (
            <div
              className="cus-shadow-2 col-span-3 md:col-span-1 hover:scale-105 transition-all duration-150 relative"
              key={index}
              onClick={() => onClickView(index)}
            >
              <img
                src={gallery?.attributes?.Photo?.data?.attributes?.url}
                className="w-full h-56 object-cover"
              />
              <div className="p-4 absolute bottom-0 bg-black w-full bg-opacity-30">
                <h1 className="text-sm font-bold text-white">
                  {gallery?.attributes?.Name}
                </h1>
                <h1 className="text-xs text-gray-200 pb-2">
                  Date : {gallery?.attributes?.EventDate}
                </h1>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default Gallery;
