import React, { useState } from "react";
import Layout from "../components/Layout";
import useAPIDataList from "../hooks/useAPIDataList";
import { ADMISSION_FEE, BASE_URL, FEE_DETAILS } from "../apiRoutes";

const API_URL_ADMISSION = BASE_URL + ADMISSION_FEE;
const API_URL_FEE = BASE_URL + FEE_DETAILS;

export default function FeeStructure() {
  const { data: admissionDetails } = useAPIDataList(API_URL_ADMISSION);
  const { data: feeDetails } = useAPIDataList(API_URL_FEE);

  return (
    <Layout>
      <div className="container mx-auto py-10 lg:py-20 px-4">
        <p className="font-medium text-lg lg:text-2xl text-center border-b-2 pb-6 border-primary">
          Fees Structure
        </p>

        <div className="relative overflow-x-auto shadow-md sm:rounded-lg my-10">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-white uppercase bg-primary-light dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Category
                </th>
                <th scope="col" className="px-6 py-3">
                  New Admission
                </th>
                <th scope="col" className="px-6 py-3">
                  Re-Admission
                </th>
                <th scope="col" className="px-6 py-3">
                  Monthly Fees
                </th>
              </tr>
            </thead>
            <tbody>
              {admissionDetails?.map((details, index) => (
                <tr
                  className={`${
                    (index + 1) % 2 === 0
                      ? "border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700"
                      : "bg-white border-b dark:bg-gray-900 dark:border-gray-700"
                  } `}
                  key={index}
                >
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {details?.attributes?.Category}
                  </th>
                  <td className="px-6 py-4 text-gray-600">
                    ₹&nbsp;{details?.attributes?.NewAdmissionFee}
                  </td>
                  <td className="px-6 py-4 text-gray-600">
                    ₹&nbsp;{details?.attributes?.ReAdmissionFee}/-
                  </td>
                  <td className="px-6 py-4 text-gray-600">
                    {details?.attributes?.MonthlyFees}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg my-10">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-white uppercase bg-primary dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Class
                </th>
                <th scope="col" className="px-6 py-3">
                  Monthly Fees
                </th>
              </tr>
            </thead>
            <tbody>
              {feeDetails?.map((details, index) => (
                <tr
                  className={`${
                    (index + 1) % 2 === 0
                      ? "border-b bg-blue-50 dark:bg-gray-800 dark:border-gray-700"
                      : "bg-white border-b dark:bg-gray-900 dark:border-gray-700"
                  } `}
                  key={index}
                >
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {details?.attributes?.Class}
                  </th>
                  <td className="px-6 py-4 text-gray-600">
                    ₹&nbsp;{details?.attributes?.MonthlyFee}/-
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Layout>
  );
}
