import React from "react";
import useAPIData from "../../hooks/useAPIData";
import { BASE_URL, PRINCIPAL_MSG_SECTION } from "../../apiRoutes";

const API_URL = BASE_URL + PRINCIPAL_MSG_SECTION + "?populate=*";

const PrincipalMessage = () => {
  const { data } = useAPIData(API_URL);
  return (
    <>
      <div className="bg-white cus-shadow-1 w-10/12 h-full rounded-md"></div>
      <div className="bg-primary-dark w-11/12 h-full absolute top-6 left-6 rounded-md shadow-xl p-5">
        <div className="flex flex-col items-center justify-center">
          <img
            src={data?.attributes?.PrincipalPhoto?.data?.attributes?.url}
            className="h-32 lg:h-40 w-32 lg:w-40 rounded-full object-cover border-4"
          />
          <h1 className="text-secondary mt-3 text-xl font-medium text-center">
            Message from Principal
          </h1>
          <h1 className="text-white pb-2">{data?.attributes?.PrincipalName}</h1>
          <p className="text-center text-gray-300 mt-3 text-sm md:text-base w-full lg:w-3/4">
            {data?.attributes?.Message}
          </p>
        </div>
      </div>
    </>
  );
};

export default PrincipalMessage;
