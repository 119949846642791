import React from "react";
import NewsCard from "../cards/NewsCard";
import PrincipalMessage from "./PrincipalMessage";
import { BASE_URL, NEWS_AND_ANNOUCEMENT } from "../../apiRoutes";
import useAPIDataList from "../../hooks/useAPIDataList";

const API_URL = BASE_URL + NEWS_AND_ANNOUCEMENT;

export default function NewsAndEvents() {
  const { data } = useAPIDataList(API_URL);
  return (
    <div className="grid grid-cols-6 gap-10 p-4 py-10 lg:py-20">
      <div className="col-span-6 lg:col-span-4 pr-10 relative h-50">
        <PrincipalMessage />
      </div>
      <div className="col-span-6 lg:col-span-2 mt-7 lg:mt-0">
        <p className="text-base md:text-lg lg:text-2xl text-primary font-medium">
          NEWS&nbsp;<span className="text-black">AND</span>&nbsp;ANNOUNCEMENTS
        </p>
        <div className="py-8 pl-10 h-50 overflow-y-scroll no-scrollbar">
          {data?.map((data, index) => (
            <>
              <NewsCard
                key={index}
                title={data.attributes?.Title}
                description={data.attributes?.ShorDescription}
                eventdate={data.attributes?.NewsDate}
              />
            </>
          ))}
        </div>
      </div>
    </div>
  );
}
