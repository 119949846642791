import React, { useState } from "react";
import Layout from "../components/Layout";
import { IStaff } from "../modals/Modals";
import { BASE_URL, NON_TEACHING_STAFF, TEACHING_STAFFS } from "../apiRoutes";
import useAPIDataList from "../hooks/useAPIDataList";

const API_TEACHING = BASE_URL + TEACHING_STAFFS + "?populate=*";
const API_NON_TEACHING = BASE_URL + NON_TEACHING_STAFF + "?populate=*";

const StaffDetails = () => {
  const { data: teachingStaff } = useAPIDataList(API_TEACHING);
  const { data: nonTeachingStaff } = useAPIDataList(API_NON_TEACHING);
  return (
    <Layout>
      <div className="container mx-auto py-8 lg:py-14 px-4">
        <h1 className="text-center text-xl md:text-2xl lg:text-3xl mb-6 lg:mb-12">
          Faculty
        </h1>
        <div>
          <h1 className="text-base md:text-lg lg:text-xl font-medium mb-6">
            Teaching Staff
          </h1>
          <div className="grid grid-cols-3 gap-6">
            {teachingStaff?.map((staff, index) => (
              <div
                className="col-span-3 lg:col-span-1 cus-shadow-2 p-2 rounded-lg flex items-center gap-3 hover:scale-105 transition-all duration-150"
                key={index}
              >
                <img
                  src={staff?.attributes?.Photo?.data?.attributes?.url}
                  className="w-36 h-28 object-cover rounded-lg"
                />
                <div>
                  <h1 className="font-bold">
                    Name : {staff?.attributes?.Name}
                  </h1>
                  <h1 className="text-sm text-gray-600 pt-1">
                    Designation : {staff?.attributes?.Designation}
                  </h1>
                  <h1 className="text-sm text-gray-600">
                    Qualification : {staff?.attributes?.Qualification}
                  </h1>
                  <h1 className="text-sm text-gray-600">
                    Experience : {staff?.attributes?.Experience}
                  </h1>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="mt-16">
          <h1 className="text-base md:text-lg lg:text-xl font-medium mb-6">
            Non Teaching Staff
          </h1>
          <div className="grid grid-cols-3 gap-4">
            {nonTeachingStaff?.map((staff, index) => (
              <div
                className="col-span-3 lg:col-span-1 cus-shadow-2 p-2 rounded-lg flex items-center gap-3 hover:scale-105 transition-all duration-150"
                key={index}
              >
                <img
                  src={staff?.attributes?.Photo?.data?.attributes?.url}
                  className="w-36 h-28 object-cover rounded-lg"
                />
                <div>
                  <h1 className="font-bold">
                    Name : {staff?.attributes?.Name}
                  </h1>
                  <h1 className="text-sm text-gray-600 pt-1">
                    Date of Joining : {staff?.attributes?.DateOfJoining}
                  </h1>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default StaffDetails;
