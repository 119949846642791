import React from "react";
import {
  Navbar,
  MobileNav,
  Typography,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  IconButton,
} from "@material-tailwind/react";
import {
  Square3Stack3DIcon,
  ChevronDownIcon,
  Bars2Icon,
  PhoneIcon,
} from "@heroicons/react/24/outline";
import Logo from "../assets/schoollogo.png";
import { Link } from "react-router-dom";

const dropDownMenuList = [
  {
    title: "About Us",
    menuItems: [
      { title: "About SWES", link: "/about-swes" },
      { title: "CBSE Affliction", link: "" },
      { title: "Facilities", link: "/facilities" },
      { title: "Important Documents", link: "/important-docs" },
    ],
  },
  {
    title: "Academics",
    menuItems: [
      { title: "Academic Calendar", link: "/academic-calendar" },
      { title: "Fee Structure", link: "/fee-struture" },
      { title: "Result Analysis", link: "/result-analysis" },
    ],
  },
  {
    title: "Administration",
    menuItems: [
      { title: "SWES members", link: "/swes-members" },
      { title: "Staff Details", link: "/staff-members" },
    ],
  },
];

// nav list menu
const navListMenuItems = [
  {
    title: "@material-tailwind/html",
  },
  {
    title: "@material-tailwind/react",
  },
  {
    title: "Material Tailwind PRO",
  },
];

interface Props {
  title: string;
  menuItems: MenuItems[];
}
interface MenuItems {
  title: string;
  link: string;
}

function NavListMenu({ title, menuItems }: Props) {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const triggers = {
    onMouseEnter: () => setIsMenuOpen(true),
    onMouseLeave: () => setIsMenuOpen(false),
  };

  const renderItems = menuItems.map((menu, index) => (
    <Link to={menu.link} key={index}>
      <MenuItem>
        <Typography
          variant="h6"
          color="blue-gray"
          className="text-sm text-gray-400 lg:text-gray-800 font-normal"
        >
          {menu.title}
        </Typography>
      </MenuItem>
    </Link>
  ));

  return (
    <React.Fragment>
      <Menu open={isMenuOpen} handler={setIsMenuOpen}>
        <MenuHandler>
          <Typography as="a" href="#" variant="small" className="font-normal">
            <MenuItem
              {...triggers}
              className="hidden items-center gap-2 text-white lg:flex lg:rounded-full"
            >
              {title}{" "}
              <ChevronDownIcon
                strokeWidth={2}
                className={`h-3 w-3 transition-transform ${
                  isMenuOpen ? "rotate-180" : ""
                }`}
              />
            </MenuItem>
          </Typography>
        </MenuHandler>
        <MenuList
          {...triggers}
          className="hidden w-[16rem] grid-cols-4 gap-3 overflow-visible lg:grid"
        >
          <ul className="col-span-4 flex w-full flex-col gap-1">
            {renderItems}
          </ul>
        </MenuList>
      </Menu>
      <MenuItem className="flex items-center gap-2 text-white lg:hidden">
        <Square3Stack3DIcon className="h-[18px] w-[18px]" /> {title}{" "}
      </MenuItem>
      <ul className="ml-6 flex w-full flex-col gap-1 lg:hidden">
        {renderItems}
      </ul>
    </React.Fragment>
  );
}

// nav list component
const navListItems = [
  {
    label: "Co-Curriculars",
    link: "/co-curriculars",
  },
  {
    label: "Gallery",
    link: "/gallery",
  },
];

function NavList() {
  return (
    <ul className="mb-4 mt-2 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center">
      {dropDownMenuList.map((item) => (
        <NavListMenu title={item.title} menuItems={item.menuItems} />
      ))}
      {navListItems.map(({ label, link }, key) => (
        <Link to={link} key={key}>
          <Typography
            key={label}
            variant="small"
            color="blue-gray"
            className="font-normal"
          >
            <MenuItem className="flex items-center gap-2 lg:rounded-full text-white">
              {label}
            </MenuItem>
          </Typography>
        </Link>
      ))}
    </ul>
  );
}

export default function ComplexNavbar() {
  const [isNavOpen, setIsNavOpen] = React.useState(false);
  const toggleIsNavOpen = () => setIsNavOpen((cur) => !cur);

  React.useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setIsNavOpen(false)
    );
  }, []);

  return (
    <div className="relative">
      <>
        <div className="bg-primary-light px-4 p-2 flex justify-end lg:justify-between items-center">
          <p className="text-white lg:ml-48 text-sm hidden lg:block">
            Sidharta Wisdom English School
          </p>
          <div className="flex items-center">
            <div className="pr-6 text-sm text-white cursor-pointer">
              <p className="flex items-center">
                <PhoneIcon className="h-4 w-4 lg:h-6 lg:w-6 mr-1" />
                Contact Us
              </p>
            </div>
            <a
              className="border border-gray-100 px-4 py-1 text-sm rounded-md text-white hover:bg-white hover:text-primary transition-all duration-500 cursor-pointer"
              href="https://api.sidhartawisdom.com/admin"
              target="_blank"
            >
              Log In
            </a>
          </div>
        </div>
        <Navbar className="p-2 lg:pl-6 w-full max-w-full shadow-none bg-primary rounded-none border-none bg-opacity-100">
          <div className="relative mx-auto flex items-center text-blue-gray-900 lg:py-4">
            <div className="absolute top-2/4 left-2/4 hidden -translate-x-2/4 -translate-y-2/4 lg:block">
              <NavList />
            </div>
            <IconButton
              size="sm"
              color="white"
              variant="text"
              onClick={toggleIsNavOpen}
              className="ml-auto mr-2 lg:hidden"
            >
              <Bars2Icon className="h-6 w-6" />
            </IconButton>
            {/* <ProfileMenu /> */}
          </div>
          <MobileNav open={isNavOpen} className="overflow-scroll">
            <NavList />
          </MobileNav>
        </Navbar>
      </>
      <div className="absolute py-2 bg-white w-16 lg:w-24 top-0 h-max lg:h-full left-4 lg:left-10 shadow flex mx-auto items-center justify-center">
        <Link to="/home">
          <img src={Logo} className="h-16 lg:h-20" />
        </Link>
      </div>
    </div>
  );
}
