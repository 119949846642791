import React, { useCallback, useEffect, useState } from "react";
import Layout from "../components/Layout";
import ImageViewer from "react-simple-image-viewer";
import { BASE_URL, IMPORTANT_DOCS } from "../apiRoutes";
import useAPIDataList from "../hooks/useAPIDataList";

const API_URL = BASE_URL + IMPORTANT_DOCS + "?populate=*";

export default function ImportantDocs() {
  const { data } = useAPIDataList(API_URL);
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [images, setImages] = useState<any>([]);

  const downloadFileFromURL = (url: string, fileName: string) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const blobUrl = window.URL.createObjectURL(new Blob([blob]));
        const aTag = document.createElement("a");
        aTag.href = blobUrl;
        aTag.setAttribute("download", fileName);
        document.body.appendChild(aTag);
        aTag.click();
        aTag.remove();
      });
  };
  const openImageViewer = useCallback((id: number) => {
    setCurrentImage(id);
    setIsViewerOpen(true);
  }, []);
  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const onClickView = (id: number, url: string, name: string) => {
    const extension = name?.split(".").pop();
    if (extension?.includes("pdf")) {
      const aTag = document.createElement("a");
      aTag.href = url;
      aTag.target = "_blank";
      document.body.appendChild(aTag);
      aTag.click();
      aTag.remove();
    } else {
      openImageViewer(id);
    }
  };

  useEffect(() => {
    if (data) {
      const images = [];
      for (let i = 0; i < data.length; i++) {
        images.push(data[i]?.attributes?.DocumentFile?.data?.attributes?.url);
      }
      setImages(images);
    }
  }, [data]);

  return (
    <Layout>
      <div className="container mx-auto py-10 lg:py-20">
        {isViewerOpen && (
          <ImageViewer
            src={images}
            currentIndex={currentImage}
            disableScroll={false}
            closeOnClickOutside={true}
            onClose={closeImageViewer}
          />
        )}
        <div className="relative overflow-x-auto">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3 whitespace-nowrap">
                  SL NO.
                </th>
                <th scope="col" className="px-6 py-3 whitespace-nowrap">
                  DOCUMENTS / INFORMATION
                </th>
                <th scope="col" className="px-6 py-3 whitespace-nowrap">
                  DOCUMENT LINK
                </th>
                <th scope="col" className="px-6 py-3">
                  ACTION
                </th>
              </tr>
            </thead>
            <tbody>
              {data?.map((doc, index) => (
                <tr
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                  key={index}
                >
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {index + 1}
                  </th>
                  <td className="px-6 py-4 uppercase whitespace-nowrap text-gray-900 font-medium">
                    {doc?.attributes?.DocumentName}
                  </td>
                  <td className="px-6 py-4 text-indigo-700">
                    <a
                      href={
                        doc?.attributes?.DocumentFile?.data?.attributes?.url
                      }
                      target="_blank"
                    >
                      {doc?.attributes?.DocumentFile?.data?.attributes?.url}
                    </a>
                  </td>
                  <td className="px-6 py-4 flex items-center">
                    <div
                      onClick={() =>
                        downloadFileFromURL(
                          doc?.attributes?.DocumentFile?.data?.attributes?.url,
                          doc?.attributes?.DocumentFile?.data?.attributes?.name
                        )
                      }
                      className="flex flex-col items-center hover:text-indigo-600 cursor-pointer mr-6"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9 8.25H7.5a2.25 2.25 0 00-2.25 2.25v9a2.25 2.25 0 002.25 2.25h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25H15M9 12l3 3m0 0l3-3m-3 3V2.25"
                        />
                      </svg>
                      <span className="text-xs">Download</span>
                    </div>
                    <div
                      onClick={() =>
                        onClickView(
                          index,
                          doc?.attributes?.DocumentFile?.data?.attributes?.url,
                          doc?.attributes?.DocumentFile?.data?.attributes?.name
                        )
                      }
                      className="flex flex-col items-center hover:text-indigo-600 cursor-pointer"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6 cursor-pointer hover:text-indigo-600"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                        />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                      </svg>
                      <span className="text-xs">View</span>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Layout>
  );
}
