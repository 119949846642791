import React from "react";
import Line from "../Line";
import useAPIData from "../../hooks/useAPIData";
import { ABOUT_SECTION, BASE_URL } from "../../apiRoutes";

const API_URL = BASE_URL + ABOUT_SECTION + "?populate=*";

export default function AboutSection() {
  const { data } = useAPIData(API_URL);
  return (
    <div className="flex items-center p-4 py-8 lg:py-20 gap-5">
      <div className="pr-5">
        <p className="text-base md:text-lg lg:text-2xl text-primary font-semibold">
          <span className="text-black">ABOUT</span>&nbsp;SIDHARTA WISDOM ENGLISH
          SCHOOL
        </p>
        <Line />
        <p className="py-4 lg:py-10 text-gray-700 leading-6 text-justify text-sm lg:text-base">
          {/* <span className="font-bold text-black">
            Sidharta Wisdom English School (SWES)&nbsp;
          </span> */}
          {data?.attributes?.Description}
        </p>
      </div>
      <div className="hidden lg:block">
        <img
          src={data?.attributes?.AboutImage?.data?.attributes?.url}
          className="rounded-lg"
        />
      </div>
    </div>
  );
}
