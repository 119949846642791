import React from "react";
import Logo from "../assets/logo.png";
import BoxLink from "../components/BoxLink";
import LandingPageImage from "../assets/landingPhoto.jpg";
import { Link } from "react-router-dom";

export default function LandingPage() {
  return (
    <div className="flex items-center justify-center h-screen px-2">
      <div className="h-98 border w-full rounded-r-lg">
        <div className="grid grid-cols-2 h-98">
          <div className="col-span-2 lg:col-span-1 flex flex-col px-4 lg:pl-24 py-10 lg:py-20">
            <div className="max-h-max">
              <img src={Logo} className="mb-8 h-16 lg:h-24 -ml-2" />
            </div>
            <div className="flex items-center flex-1">
              <div>
                <h1 className="text-2xl lg:text-6xl font-semibold pb-2">
                  Sidharta Wisdom
                </h1>
                <h1 className="text-2xl lg:text-6xl font-semibold">
                  Welfare Society
                </h1>
                <p className="py-10 lg:text-lg text-gray-600">
                  The Sidharta Wisdom Welfare Society was founded by Mr Hem
                  Basumatary and Smti Leiki Chojom, the main motive of the SWES
                  is to improve literacy and poor health condition of women,
                  children and destitute by the way of building the capacity of
                  the community level persons, making them functionally literate
                  and to provide avenues for income generation and to improve
                  overall condition of women and children in society. Also, to
                  carry out welfare activities for women, children, destitute
                  and aged and to play very important role for Tourism and
                  Cultural Developement
                </p>
                <div className="flex items-center gap-6 mt-6 flex-col md:flex-row">
                  <BoxLink link="asdasd" label="Visit Child Care Institute" />
                  <Link to="/home">
                    <BoxLink link="asdasd" label="Visit School Web Site" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-1 hidden lg:block">
            <div className="relative h-full bg-gray-300">
              <img
                src={LandingPageImage}
                className="h-full w-full object-cover rounded-r-lg"
              />
              <div className="h-full w-full bg-black top-0 absolute rounded-r-lg opacity-30"></div>
              <svg
                width="200"
                fill="#fff"
                xmlns="http://www.w3.org/2000/svg"
                className="h-full absolute top-0"
              >
                <path d="M0 0H200L71.5543 1126H0V0Z" fill="white" />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
