import React from "react";
import useAPIData from "../../hooks/useAPIData";
import { BASE_URL, HERO_SECTION } from "../../apiRoutes";

const API_URL = BASE_URL + HERO_SECTION + "?populate=*";

const HeroSection = () => {
  const { data } = useAPIData(API_URL);

  return (
    <div className="h-20vh md:h-40vh xl:h-60vh relative w-full">
      <img
        src={data?.attributes?.CoverImage?.data?.attributes?.url}
        className="h-full w-full object-cover"
      />
      <div className="h-full w-full bg-black top-0 absolute bg-opacity-50 flex justify-center items-center">
        <p className="text-white text-xl sm:text-2xl md:text-3xl xl:text-5xl w-3/4 sm:w-1/2 font-medium text-center">
          {data?.attributes?.Title}
        </p>
      </div>
    </div>
  );
};

export default HeroSection;
