import React from "react";
import Layout from "../components/Layout";
import SwesImage from "../assets/AboutUsImage.jpeg";
import useAPIData from "../hooks/useAPIData";
import { ABOUT_SECTION, BASE_URL } from "../apiRoutes";

const API_URL = BASE_URL + ABOUT_SECTION + "?populate=*";

const AboutSWES = () => {
  const { data } = useAPIData(API_URL);
  return (
    <Layout>
      <>
        <div className="container mx-auto py-20 relative">
          <div className="grid grid-cols-2 gap-8 mt-10">
            <div className="col-span-1">
              <div className="rounded-md text-sm md:text-base text-black mt-8">
                <h1 className="text-xl md:text-2xl lg:text-3xl mb-4 lg:mb-10 font-bold">
                  About
                </h1>
                {data?.attributes?.Description}
              </div>
            </div>
            <div className="col-span-1 flex justify-center">
              <div className="h-96 w-96 curved-radius overflow-hidden">
                <img
                  src={data?.attributes?.AboutImage?.data?.attributes?.url}
                  className="object-cover h-full"
                />
              </div>
            </div>
          </div>
          <h1 className="text-xl md:text-2xl lg:text-3xl mb-4 lg:mb-10 font-bold mt-16">
            Aims & Objectives
          </h1>
          <ul className="pb-14">
            <li className="border py-3 px-6 mb-3 relative">
              To create and involve the rural people irrespective of age,
              religion, caste, sex etc. in active participation with their sole
              voluntary spirit of the social workers in the endeavor to create
              more equal and benifical order.
              <div className="w-4 h-4 bg-primary absolute -left-2 top-1/2 -translate-y-1/2"></div>
            </li>
            <li className="border py-3 px-6 mb-3 relative">
              Focus mainly on school and education activities.
              <div className="w-4 h-4 bg-primary absolute -left-2 top-1/2 -translate-y-1/2"></div>
            </li>
            <li className="border py-3 px-6 mb-3 relative">
              To run and operate a residential school with hostel and fooding
              facility under the name and style of 'Sidharta Wisdom English
              School'
              <div className="w-4 h-4 bg-primary absolute -left-2 top-1/2 -translate-y-1/2"></div>
            </li>
            <li className="border py-3 px-6 mb-3 relative">
              To mortgage societies assets to secure any kind of loans from
              banks or any other accredited financial institutions.
              <div className="w-4 h-4 bg-primary absolute -left-2 top-1/2 -translate-y-1/2"></div>
            </li>
            <li className="border py-3 px-6 mb-3 relative">
              To undertake activities like Low Cost Housing, Low Cost Sanitary
              Latrines, Smokeless Chula, Pure Drinking Water, Public Ponds,
              Underground Water, Roof Top Water Harvesting, Weaving,
              Handicrafts, Blacksmiths, Irrigation Canals, Check-Dams to carry
              out relief and rehabilitation works during natural calamities of
              floods draughts, famine, fire, epidemics etc. and also to provide
              relief and credit to displaced and handicapped person, destitute
              aged, women and child labour, in the community, Social Forestry,
              Medical Plantation, Oil-seed Plantation, Rubber Plantation,
              Bee-keeping, Angora Rabbit, Goatery, Animal Husbandry,
              Bio-fertilizer, Hindi & Sanskrit School, Promote tourism,
              Agriculture and Horticulture, Upliftment of highlander Yak readers
              etc.
              <div className="w-4 h-4 bg-primary absolute -left-2 top-1/2 -translate-y-1/2"></div>
            </li>
            <li className="border py-3 px-6 mb-3 relative">
              To construct Medical College, Health Service Center, Nurse/Midwife
              Training center, Research and training center for Herbal
              medicines, Homeopathic drugs, Ayurvedic drugsm Cultiral Hall,
              Working Women Hostel, Shohrt Stay home, School building, Veteinary
              Hospital, Cold storage, Food Park, Old Age Home, Godown, Community
              Halls, Hostel for Boys and Girls, Cold Storage, Information
              Technology(IT) Center, Museum, Swimming Pool, Tea Processing
              Center, Common Facilities Center, Rural Business Hub, Shopping
              Complex, Emporium (Outlet Center), B.Ed College, Dental College,
              Engineering College, Research & Laboratory Center for
              HIV/AIDS/leprosy Patients & Solar Energy Plants, Wind Mill, Hydel
              Power Plant etc.
              <div className="w-4 h-4 bg-primary absolute -left-2 top-1/2 -translate-y-1/2"></div>
            </li>
          </ul>
        </div>
      </>
    </Layout>
  );
};

export default AboutSWES;
