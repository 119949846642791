import React from "react";
import { BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";
import { BASE_URL, FACILITY_SECTION } from "../../apiRoutes";
import useAPIDataList from "../../hooks/useAPIDataList";

const API_URL = BASE_URL + FACILITY_SECTION + "?populate=*";

const Features = () => {
  const { data } = useAPIDataList(API_URL);
  return (
    <div>
      <h1 className="text-center text-xl lg:text-2xl xl:text-3xl font-bold mb-10">
        Our Facilities
      </h1>
      <div className="grid grid-cols-3 gap-5">
        {data?.slice(0, 3).map((facility, index) => (
          <div className="col-span-3 md:col-span-1" key={index}>
            <div className="">
              <img
                src={facility?.attributes?.FacilityPhoto?.data?.attributes?.url}
                className="h-60 w-full object-cover"
              />
              <h1 className="text-lg font-medium py-2 text-center">
                {facility?.attributes?.FacilityName}
              </h1>
              <p className="text-sm text-center text-gray-700">
                {facility?.attributes?.ShortDescription}
              </p>
            </div>
          </div>
        ))}
      </div>
      <div className="flex items-center justify-center pt-8">
        <Link to="/facilities">
          <button className="text-primary flex items-center gap-2 hover:scale-110 duration-100 transition-all">
            View More <BsArrowRight />
          </button>
        </Link>
      </div>
    </div>
  );
};

export default Features;
