import React from "react";
import Layout from "../components/Layout";
import useAPIDataList from "../hooks/useAPIDataList";
import { BASE_URL, FACILITY_SECTION } from "../apiRoutes";

const API_URL = BASE_URL + FACILITY_SECTION + "?populate=*";

const Facilities = () => {
  const { data } = useAPIDataList(API_URL);

  return (
    <Layout>
      <div className="container mx-auto py-10">
        <h1 className="text-center text-xl lg:text-2xl xl:text-3xl font-bold mb-10">
          Our Facilities
        </h1>
        {/* <div className="p-6 bg-gray-200 mb-10 rounded-md">
          <p className="text-base text-gray-700">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus
            PageMaker including versions of Lorem Ipsum.
          </p>
        </div> */}
        <div className="grid grid-cols-3 gap-8">
          {data?.map((facility, index) => (
            <div className="col-span-3 md:col-span-1" key={index}>
              <div className="">
                <img
                  src={
                    facility?.attributes?.FacilityPhoto?.data?.attributes?.url
                  }
                  className="h-60 w-full object-cover"
                />
                <h1 className="text-lg font-medium py-2 text-center">
                  {facility?.attributes?.FacilityName}
                </h1>
                <p className="text-sm text-center text-gray-700">
                  {facility?.attributes?.ShortDescription}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default Facilities;
