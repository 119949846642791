import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import { truncateString } from "../utilities/getDayFromDate";
import { BASE_URL, CO_CURRICULAR_ACTIVITY } from "../apiRoutes";
import useAPIDataList from "../hooks/useAPIDataList";

const API_URL = BASE_URL + CO_CURRICULAR_ACTIVITY + "?populate=*";

interface ApiResponse {
  data: {
    attributes: any;
    id: number;
  };
}

const CoCurricular = () => {
  const { data } = useAPIDataList(API_URL);
  const [selectedActivity, setSelectedActivity] =
    useState<ApiResponse["data"]>();
  const onClickRow = (data: any) => {
    setSelectedActivity(data);
  };
  useEffect(() => {
    if (data) {
      setSelectedActivity(data[0]);
    }
  }, [data]);
  return (
    <Layout>
      <div className="container mx-auto py-12 lg:py-20">
        <h1 className="text-center text-xl lg:text-2xl xl:text-3xl font-bold mb-8 lg:mb-16">
          Co-Curricular Activities
        </h1>
        <div className="hidden lg:grid lg:grid-cols-5">
          <div className="col-span-5 lg:col-span-2 border-r p-4 h-60vh overflow-y-scroll no-scrollbar">
            {data?.map((data, index) => (
              <div
                key={index}
                className={`flex items-center  cursor-pointer mb-4 h-36 overflow-y-scroll no-scrollbar ${
                  selectedActivity?.id === data?.id
                    ? "bg-gray-300"
                    : "hover:bg-gray-100"
                }`}
                onClick={() => onClickRow(data)}
              >
                <img
                  src={data?.attributes?.ActivityImage?.data?.attributes?.url}
                  className="w-40 h-36 object-cover"
                />
                <div className="px-4">
                  <h1 className={`text-base font-bold`}>
                    Activity : {data?.attributes?.ActivityName}
                  </h1>
                  <h1 className={`text-sm  font-medium pb-1 text-gray-900`}>
                    Time : {data?.attributes?.ActivityTimeDetails}
                  </h1>
                  <p className={`text-xs`}>
                    {truncateString(data?.attributes?.ActivityDescription, 100)}
                  </p>
                </div>
              </div>
            ))}
          </div>
          <div className="col-span-5 lg:col-span-3 px-8 h-60vh overflow-y-scroll no-scrollbar">
            <img
              src={
                selectedActivity?.attributes?.ActivityImage?.data?.attributes
                  ?.url
              }
              className="w-full h-64 object-cover"
            />
            <h1 className="text-base md:text-lg font-bold mt-6">
              Activity : {selectedActivity?.attributes?.ActivityName}
            </h1>
            <h1 className="text-base text-gray-900 font-medium pb-3">
              Time : {selectedActivity?.attributes?.ActivityTimeDetails}
            </h1>
            <p className="text-base text-gray-600">
              {selectedActivity?.attributes?.ActivityDescription}
            </p>
          </div>
        </div>
        {data?.map((data) => (
          <div className="px-4 mb-6 lg:hidden">
            <img
              src={data?.attributes?.ActivityImage?.data?.attributes?.url}
              className="w-full h-36 md:h-64 object-cover"
            />
            <h1 className="text-base md:text-lg font-bold mt-3 lg:mt-6">
              Activity : {data?.attributes?.ActivityName}
            </h1>
            <h1 className="text-sm text-gray-900 font-medium pb-3">
              Time : {data?.attributes?.ActivityTimeDetails}
            </h1>
            <p className="text-sm lg:text-base text-gray-600">
              {data?.attributes?.ActivityDescription}
            </p>
          </div>
        ))}
      </div>
    </Layout>
  );
};

export default CoCurricular;
