export const BASE_URL = "https://api.sidhartawisdom.com/api/";
export const HERO_SECTION = "hero-section";
export const ABOUT_SECTION = "about-swes";
export const KEY_INFO_SECTION = "key-information";
export const FACILITY_SECTION = "our-facilities";
export const PRINCIPAL_MSG_SECTION = "message-from-principal";
export const SCHOOL_CONTACT = "school-contact";
export const ADMISSION_FEE = "admission-fee-details";
export const FEE_DETAILS = "fees-structures";
export const SWES_MEMBERS = "swes-members";
export const TEACHING_STAFFS = "teaching-staffs";
export const NON_TEACHING_STAFF = "non-teaching-staffs";
export const CO_CURRICULAR_ACTIVITY = "co-curricular-activities";
export const GALLERY = "galleries";
export const NEWS_AND_ANNOUCEMENT = "new-and-annoucements";
export const CORE_TEAMS = "core-teams";
export const IMPORTANT_DOCS = "important-documents";
