import React from "react";
import Layout from "../components/Layout";
import { BASE_URL, SWES_MEMBERS } from "../apiRoutes";
import useAPIDataList from "../hooks/useAPIDataList";

const API_URL = BASE_URL + SWES_MEMBERS + "?populate=*";

const SwesMembers = () => {
  const { data } = useAPIDataList(API_URL);
  return (
    <Layout>
      <div className="container mx-auto py-8 lg:py-14 px-4">
        <h1 className="text-center text-xl md:text-2xl lg:text-3xl mb-6 lg:mb-12">
          SWES Members
        </h1>
        <div className="lg:mt-16">
          <div className="grid grid-cols-3 gap-4">
            {data?.map((staff, index) => (
              <div
                className="col-span-3 lg:col-span-1 cus-shadow-2 p-2 rounded-lg flex items-center gap-3 hover:scale-105 transition-all duration-150"
                key={index}
              >
                <img
                  src={staff?.attributes?.Photo?.data?.attributes?.url}
                  className="w-36 h-28 object-cover rounded-lg"
                />
                <div>
                  <h1 className="font-bold text-sm md:text-base">
                    Name : {staff?.attributes?.Name}
                  </h1>
                  <h1 className="text-sm text-gray-600 pt-1">
                    Designation : {staff?.attributes?.Designation}
                  </h1>
                  <h1 className="text-sm text-gray-600">
                    Qualification : {staff?.attributes?.Qualification}
                  </h1>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default SwesMembers;
