import React from "react";
import {
  PhoneIcon,
  MapPinIcon,
  EnvelopeIcon,
} from "@heroicons/react/24/outline";
import useAPIData from "../hooks/useAPIData";
import { BASE_URL, SCHOOL_CONTACT } from "../apiRoutes";

const API_URL = BASE_URL + SCHOOL_CONTACT;

export default function Footer() {
  const { data } = useAPIData(API_URL);
  return (
    <>
      <div className="bg-primary w-full pt-20 pb-10 px-6 lg:px-0">
        <div className="container mx-auto">
          <div className="grid grid-cols-3 gap-6">
            <div className="col-span-3 lg:col-span-1 lg:flex lg:justify-center">
              <div>
                <p className="text-lg lg:text-2xl text-white">Contact Us</p>
                <div className="w-12 border border-secondary mt-2"></div>
                <div className="flex flex-col gap-3 mt-8 text-sm lg:text-base">
                  <div className="flex items-center">
                    <MapPinIcon className="h-6 w-6 mr-2 text-secondary" />
                    <p className="text-white">
                      {data?.attributes?.SchoolAddress}
                    </p>
                  </div>
                  <div className="flex items-center">
                    <PhoneIcon className="h-6 w-6 mr-2 text-secondary" />
                    <p className="text-white">
                      {data?.attributes?.ContactNumber}
                    </p>
                  </div>
                  <div className="flex items-center">
                    <EnvelopeIcon className="h-6 w-6 mr-2 text-secondary" />
                    <p className="text-white">
                      {data?.attributes?.ContactEmail}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-3 lg:col-span-1 lg:flex lg:justify-center">
              <div>
                <p className="text-lg lg:text-2xl text-white mt-6 lg:mt-0">
                  For Admissions
                </p>
                <div className="w-12 border border-secondary mt-2"></div>
                <div className="flex flex-col gap-3 mt-8 text-sm lg:text-base">
                  <div className="flex items-center">
                    <PhoneIcon className="h-6 w-6 mr-2 text-secondary" />
                    <p className="text-white">
                      {data?.attributes?.ContactNumber}
                    </p>
                  </div>
                  <div className="flex items-center">
                    <EnvelopeIcon className="h-6 w-6 mr-2 text-secondary" />
                    <p className="text-white">
                      {data?.attributes?.ContactEmail}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-3 lg:col-span-1 lg:flex lg:justify-center">
              <div>
                <p className="text-lg lg:text-2xl text-white mt-6 lg:mt-0">
                  Useful Links
                </p>
                <div className="w-12 border border-secondary mt-2"></div>
                <div className="flex flex-col gap-3 mt-8 text-sm lg:text-base">
                  <p className="text-white">About</p>
                  <p className="text-white">Academics</p>
                  <p className="text-white">Administration</p>
                  <p className="text-white">Activities</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-primary-dark p-4">
        <div className="container mx-auto">
          <p className="text-gray-200 text-center text-xs lg:text-sm">
            Copyright ©2023 Sidharta Wisdom English School. All Rights Reserved
            | Privacy Policy
          </p>
          <p className="text-gray-300 text-center text-xs lg:text-sm opacity-70 pt-1">
            Developed by Bibek Tamang, bibektamang.0007@gmail.com
          </p>
        </div>
      </div>
    </>
  );
}
