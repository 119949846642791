import { BrowserRouter, Routes, Route, HashRouter } from "react-router-dom";
import "./App.css";
import LandingPage from "./pages/LandingPage";
import HomePage from "./pages/HomePage";
import ImportantDocs from "./pages/ImportantDocs";
import FeeStructure from "./pages/FeeStructure";
import AcademicCalendar from "./pages/AcademicCalendar";
import Result from "./pages/Result";
import StaffDetails from "./pages/StaffDetails";
import Facilities from "./pages/Facilities";
import Gallery from "./pages/Gallery";
import SwesMembers from "./pages/SwesMembers";
import AboutSWES from "./pages/AboutSWES";
import CoCurricular from "./pages/CoCurricular";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/important-docs" element={<ImportantDocs />} />
          <Route path="/fee-struture" element={<FeeStructure />} />
          <Route path="/academic-calendar" element={<AcademicCalendar />} />
          <Route path="/result-analysis" element={<Result />} />
          <Route path="/staff-members" element={<StaffDetails />} />
          <Route path="/facilities" element={<Facilities />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/swes-members" element={<SwesMembers />} />
          <Route path="/about-swes" element={<AboutSWES />} />
          <Route path="/co-curriculars" element={<CoCurricular />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
