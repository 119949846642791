import React from "react";
import Layout from "../components/Layout";
import HeroSection from "../components/home/HeroSection";
import AboutSection from "../components/home/AboutSection";
import NewsAndEvents from "../components/home/NewsAndEvents";
import CoreTeam from "../components/home/CoreTeam";
import GallerySection from "../components/home/GallerySection";
import ContactUs from "../components/home/ContactUs";
import Features from "../components/home/Features";
import KeyInformation from "../components/home/KeyInformation";

export default function HomePage() {
  return (
    <>
      <Layout>
        <div className="bg-gray-100">
          <HeroSection />
          <div className="container mx-auto">
            <AboutSection />
          </div>
          <div className="bg-ternary-light">
            <div className="container mx-auto ">
              <NewsAndEvents />
            </div>
          </div>
          <KeyInformation />
          <div className="bg-ternary-light py-20">
            <div className="container mx-auto px-4">
              <Features />
            </div>
          </div>
          <div className="w-full background-image">
            <div className="h-full w-full bg-primary-dark bg-opacity-90">
              <div className="container mx-auto">
                <CoreTeam />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
